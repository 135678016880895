<template>
	<v-card class="pa-5 mt-5 text-center" color="secondary">
		<v-card-title class="d-block text-h4 mb-5">choose your plan</v-card-title>
		<v-card-text>
			<v-row class="justify-center">
				<v-col cols="12" md="6" lg="4" class="px-0 px-md-3" v-for="price in pricesOrdered">
					<v-card color="transparent">
						<v-card class="price pb-4">
							<v-card-title class="text-h6 accent">per {{ price.recurring.interval }}</v-card-title>
							<v-card-text class="mt-12">
								<h4 class="text-h5">{{ price.unit_amount / 100 | format_currency(price.currency) }}</h4>
							</v-card-text>
							<v-card-actions>
								<v-spacer></v-spacer>
								<v-btn @click="checkout(price.id)" rounded class="px-5" color="accent">subscribe</v-btn>
								<v-spacer></v-spacer>
							</v-card-actions>
						</v-card>
					</v-card>
				</v-col>
			</v-row>
		</v-card-text>
	</v-card>
</template>

<script>
import {sync} from "vuex-pathify";

export default {
	name: "RStripe",
	mounted() {
		this.getPrices()
	},
	computed: {
		user: sync('user/user'),
		pricesOrdered() {
			return this.prices.sort((a, b) => a.unit_amount - b.unit_amount)
		}
	},
	data: () => ({
		prices: []
	}),
	methods: {
		getPrices() {
			this.appLoading = true
			this.$api.stripe.prices()
				.then(response => this.prices = response.data)
				.catch(error => console.log(error))
				.finally(() => this.appLoading = false)
		},
		checkout(price_id) {
			this.appLoading = true
			this.$api.stripe.checkout(price_id)
				.then(response => {
					if (response.data.url) {
						window.open(response.data.url, '_blank');
					}
					console.log(response)
				})
				.catch(error => console.log(error))
				.finally(() => this.appLoading = false)
		}
	}
}
</script>