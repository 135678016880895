<template>
	<v-card class="pa-5 mt-5 text-center" color="secondary">
		<v-card-title class="d-block text-h4 mb-5">choose your plan</v-card-title>
		<v-card-text>
			<v-row class="justify-center">
				<template v-for="product in offerings">
					<v-col cols="12" md="6" lg="4" class="px-0 px-md-3" v-for="sku in product.skus">
						<v-card class="price">
							<v-card-title class="text-h6 accent">{{ sku.product.description }}</v-card-title>
							<v-card-text class="mt-15  text-center">
								<h4 class="text-h5 text-center">{{ sku.product.price | format_currency(sku.product.currencyCode) }}</h4>
							</v-card-text>
							<v-card-subtitle>* prices include VAT</v-card-subtitle>
							<v-card-actions>
								<v-spacer></v-spacer>
								<v-btn @click="purchase(sku)" rounded class="px-5" color="accent">subscribe</v-btn>
								<v-spacer></v-spacer>
							</v-card-actions>
						</v-card>
					</v-col>
				</template>
			</v-row>
		</v-card-text>
	</v-card>
</template>

<script>
import {Glassfy} from "capacitor-plugin-glassfy" ;
import {call, get} from "vuex-pathify";
import {toast} from "@/utils/helpers";

export default {
	name: "RAppStore",
	computed: {
		offerings: get('app_store/offerings')
	},
	data: () => ({
		offerings: []
	}),
	beforeMount() {
		this.initStore()
	},
	methods: {
		async initStore() {
			this.appLoading = true
			try {
				await Glassfy.initialize({apiKey: 'e04578662c1f48c5bbda93f0b240ec57', watcherMode: false});
				Glassfy.offerings()
					.then(response => {
						this.offerings = response.all
					})
					.catch(error => {
							console.log(error)
							toast.add('error fetching products', 'error')
						}
					)
					.finally(() => this.appLoading = false)
			} catch (e) {
				console.log(e);
				toast.add('error contacting store', 'error')
				this.appLoading = false
			}
		},
		async purchase(sku) {
			this.appLoading = true
			try {
				const transaction = await Glassfy.purchaseSku({sku: sku});
				const permission = transaction.permissions.all.find((p) => p.permissionId === "premium");
				if (permission && permission.isValid) {
					this.$api.iap.update()
						.then(response => {
								this.user = response.data;
								this.$router.push({name: 'Home'})
								toast.add('Subscription created successfully', 'success')
							}
						)
						.catch(error => {
								console.log(error)
								toast.add('There was an error, please try again.', 'error')
							}
						)
						.finally(() => this.appLoading = false)
				}
			} catch (e) {
				this.appLoading = false
				toast.add('The was an error.', 'error')
				console.log(e);
			}
		},
	}
}
</script>