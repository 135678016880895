<template>
	<v-container fluid>
		<v-app-bar fixed color="background" elevation="0" class="py-lg-2 pr-lg-3 pr-1">
			<v-btn icon rounded exact :to="{name: 'Account'}">
				<v-icon>mdi-arrow-left</v-icon>
			</v-btn>
			<v-spacer></v-spacer>
		</v-app-bar>
		<v-container>
			<r-app-store v-if="$platform === 'ios'"></r-app-store>
			<r-stripe v-else></r-stripe>
		</v-container>
		<v-container>
			<v-card class="px-5 py-1 mt-5" color="secondary">
				<v-card-text>
					<v-row justify="center">
						<v-col cols="auto"><a href="https://rewind-remind.me/wp-content/uploads/2021/06/Rewind-Life-Ltd_Terms-and-Conditions.DOCX-0001.pdf" target="_blank" referrerpolicy="no-referrer">terms & conditions</a></v-col>
					</v-row>
				</v-card-text>
			</v-card>
			<v-card class="px-5 py-1 mt-5" color="secondary">
				<v-card-text>
					<v-row justify="center">
						<v-col cols="auto"><a href="https://rewind-remind.me/wp-content/uploads/2022/11/Rewind-Life-Ltd_Privacy-Notice.DOCX.pdf" target="_blank" referrerpolicy="no-referrer">privacy policy</a></v-col>
					</v-row>
				</v-card-text>
			</v-card>
		</v-container>
	</v-container>
</template>

<script>
import {sync} from "vuex-pathify";
import RStripe from './components/RStripe'
import RAppStore from './components/RAppStore'

export default {
	name: "Store",
	metaInfo: {
		title: 'Account',
	},
	components: {RStripe, RAppStore},
	computed: {
		user: sync('user/user'),
	},
	mounted() {

	},
	methods: {

	}
}
</script>